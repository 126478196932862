
import { Component, Prop, Vue } from "vue-property-decorator";
import Upload from "./Upload.vue";
import Preloader from "./Preloader.vue";

import { EImportType } from "../enums/import_type";
import { EImportJobStatus } from "../enums/import_job_status";
import { EImportCompletionCode } from "../enums/import_completion_code";
import { ImportJob } from "../models/import/import_job";
import CInfiniteScroll from "./InfiniteScroll.vue";
import { ProgressCircle } from "vue-progress-circle";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { ImportError } from "../models/import/import_error";

@Component({
  name: "CImportModal",
  components: {
    Upload,
    Preloader,
    CInfiniteScroll,
    ProgressCircle,
    RecycleScroller
  },
  data: () => {
    return {
      EImportJobStatus,
      EImportCompletionCode,
      EImportType
    };
  },
  channels: {
    ImportJobsQueueChannel: {
      received(data: {}) {
        this.$store.commit(
          "importJobUpdated",
          (data as { updated_job: ImportJob }).updated_job
        );
      },
      connected() {
        // do nothing.
      },
      rejected() {
        // do nothing.
      },
      disconnected() {
        // do nothing.
      }
    }
  }
})
export default class CImportModal extends Vue {
  @Prop({ required: true }) private import_type!: EImportType;

  private watchers: Function[] = [];

  private file_url: string | null = null;
  private import_jobs: ImportJob[] = [];

  private isLoaded: boolean = false;
  private per_page: number = 20;
  private current_page: number = 0;
  private next_page: boolean = true;
  private static_uploader_url = process.env.VUE_APP_STATIC_UPLOADER;

  private created() {
    this.loadJobs();

    this.watchers.push(
      this.$store.watch(
        state => state.updatedJob,
        updatedJob => {
          const index = this.import_jobs.findIndex(
            job => job.id === updatedJob.id
          );
          if (index >= 0) {
            // произошёл успешный импорт
            if (
              this.import_jobs[index].exit_code !==
                EImportCompletionCode.SUCCESS &&
              updatedJob.exit_code === EImportCompletionCode.SUCCESS
            ) {
              this.$emit("onImportSucceed");
            }

            this.$set(this.import_jobs, index, updatedJob);
          }
        }
      )
    );

    this.$cable.subscribe({
      channel: "ImportJobsQueueChannel"
    });
  }

  public async loadJobs() {
    this.isLoaded = false;
    this.current_page = -1;

    await this.onLoadMore();
    this.import_jobs.find(
      job => this.getImportJobStatus(job) === EImportJobStatus.IN_PROGRESS
    );
    this.isLoaded = true;
  }

  public toggleImportJob(import_job: ImportJob) {
    // пока попытка не завершилась, её невозможно развернуть
    if (typeof import_job.exit_code === "number") {
      this.$set(import_job, "toggle", !import_job.toggle);

      if (!import_job.import_errors) {
        this.isLoaded = false;
        this.$api
          .get(`/v2/import_jobs/import_errors/${import_job.id}`)
          .then((res: { data: ImportError[] }) => {
            this.$set(import_job, "import_errors", res.data);
            this.isLoaded = true;
          });
      }
    }
  }

  private createImportJob(file: Blob) {
    if (!this.file_url) {
      return;
    }
    const form_data = new FormData();
    form_data.append("file", file);
    form_data.append("import_type", this.import_type.toString());
    form_data.append("file_url", this.file_url);

    this.$api
      .post(`/v2/import_jobs`, form_data, {})
      .then((res: { data: ImportJob }) => {
        this.import_jobs.unshift(res.data);
      });
  }

  private cancelJob(import_job: ImportJob) {
    this.$api.post(`/v2/import_jobs/cancel/${import_job.id}`, {});
  }

  private repeatJob(import_job: ImportJob) {
    this.$api.post(`/v2/import_jobs/repeat/${import_job.id}`, {});
  }

  public onLoadImportJobs(page: number) {
    return this.$api.get(
      `/v2/import_jobs?import_type=${this.import_type}&per_page=${this.per_page}&page=${page}`
    );
  }

  public onLoadMore() {
    if (this.next_page) {
      this.next_page = false;
      this.onLoadImportJobs(this.current_page + 1).then(res => {
        this.current_page = res.data.current_page;
        this.next_page = res.data.next_page;
        this.import_jobs.push(...res.data.import_jobs);
      });
    }
  }

  public onShowModal() {
    this.$modal.show("importModal");
  }

  private getImportJobStatus(import_job: ImportJob): EImportJobStatus | null {
    if (!import_job.started_at) {
      return EImportJobStatus.AWAITING;
    } else if (typeof import_job.exit_code === "number") {
      switch (import_job.exit_code) {
        case EImportCompletionCode.SUCCESS:
          if (import_job.success_count) {
            return EImportJobStatus.COMPLETE;
          } else {
            // если по итогу попытки не было импортировано ни одной строки, то отображать ошибку
            return EImportJobStatus.ERROR;
          }
        case EImportCompletionCode.CANCELED:
          return EImportJobStatus.CANCELED;
        case EImportCompletionCode.EXCEPTION:
          return EImportJobStatus.ERROR;
        case EImportCompletionCode.INVALID_FILE_STRUCTURE:
          return EImportJobStatus.ERROR;
      }
    } else if (import_job.started_at && !import_job.completed_at) {
      return EImportJobStatus.IN_PROGRESS;
    }
    return null;
  }

  private getImportTypeText() {
    switch (this.import_type) {
      case EImportType.POSITIONS:
        return "должностей";
      case EImportType.LEVELS:
        return "уровней развития";
      case EImportType.STRUCTURES:
        return "подразделений";
    }
  }

  private beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}


import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import admission_routes from "@/api/routes/admission";

import Search from "@/components/Search.vue";
import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import { AdmissionRole } from "@/models/admission/role";

import CAdmissionRoleChange, {
  IChangedAdmissionRole
} from "./components/RoleChange.vue";

import CAdmissionRoleDelete, {
  IDeletedAdmissionRole
} from "./components/RoleDelete.vue";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly roles: Array<Omit<AdmissionRole, "permissions">>;
}

@Component({
  name: "VAdmissionRoles",
  components: {
    Search,
    Preloader,
    CInfiniteScroll,
    CAdmissionRoleChange,
    CAdmissionRoleDelete
  }
})
export default class VAdmissionRoles extends Vue {
  public preload: boolean = false;
  public preload_infinite: boolean = false;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private search: string = this.$store.state.search;

  public roles: IData["roles"] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        async search => {
          this.search = search;

          await this.initHandler();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedAdmissionRole,
        (changedAdmissionRole: IChangedAdmissionRole) => {
          if (changedAdmissionRole.role_index != -1) {
            if (changedAdmissionRole.role_index != null) {
              this.$set(
                this.roles,
                changedAdmissionRole.role_index,
                changedAdmissionRole.role
              );
            } else {
              this.roles.unshift(changedAdmissionRole.role);
            }
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.deletedAdmissionRole,
        (deletedAdmissionRole: IDeletedAdmissionRole) => {
          if (deletedAdmissionRole.role_index != -1) {
            if (deletedAdmissionRole.role_index != null) {
              this.$delete(this.roles, deletedAdmissionRole.role_index);
            }
          }
        }
      )
    );

    await this.initHandler();
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadRoles(this.current_page + 1);
    }
  }

  private async initHandler() {
    this.preload = true;
    this.roles = [];

    await this.loadRoles().finally(() => (this.preload = false));
  }

  private async loadRoles(page: number = 0) {
    this.preload_infinite = true;

    return this.$api
      .get(admission_routes.roles, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.roles.push(...plainToClass(AdmissionRole, res.roles));
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  public createRole() {
    this.$store.commit("changingAdmissionRole", {
      id: null,
      role_index: null
    });
  }

  public updateRole(id: string | null, role_index: number) {
    this.$store.commit("changingAdmissionRole", {
      id,
      role_index
    });
  }

  public deleteRole(
    role: Omit<AdmissionRole, "permissions">,
    role_index: number
  ) {
    this.$store.commit("deletingAdmissionRole", {
      id: role.id,
      value: role.value,
      role_index
    });
  }
}

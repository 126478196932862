
import { Component, Vue } from "vue-property-decorator";

import skill_template_routes from "@/api/routes/skill_templates";

export interface IDeletingSkillTemplate {
  id: string;
  value: string;
  template_index: number;
}

export interface IDeletedSkillTemplate {
  template_index: number;
}

@Component({
  name: "CSkillTemplateDelete"
})
export default class CSkillTemplateDelete extends Vue {
  private modal_opened: boolean = false;

  public template_id: string | null = null;
  public template_value: string | null = null;
  private template_index: number | null = null;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.deletingSkillTemplate,
        (changingSkillTemplate: IDeletingSkillTemplate | null) => {
          this.initHandler(changingSkillTemplate);
        }
      )
    );

    if (this.$store.state.deletingSkillTemplate && !this.modal_opened) {
      this.initHandler(this.$store.state.changingSkillTemplate);
    }
  }

  private initHandler(template: IDeletingSkillTemplate | null) {
    if (template != null) {
      this.template_id = template.id;
      this.template_value = template.value;
      this.template_index = template.template_index;

      this.showModal();
    } else {
      this.template_id = null;
      this.template_value = null;
      this.template_index = null;
    }
  }

  public showModal() {
    this.$modal.show("delete_skill_template_modal");
    this.modal_opened = true;
  }

  public hideModal() {
    this.$modal.hide("delete_skill_template_modal");
    this.modal_opened = false;

    this.$store.commit("deletingSkillTemplate", null);
  }

  public async deleteTemplate() {
    if (!this.template_id) {
      return;
    }

    return this.$api
      .destroy(skill_template_routes.template(this.template_id))
      .then(() => {
        this.$store.commit("deletedSkillTemplate", {
          template_index: this.template_index
        });

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Шаблон отправлен в архив",
          speed: 500
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

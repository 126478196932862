import "reflect-metadata";

import { IsUUID, IsString, IsOptional } from "class-validator";
import { Type } from "class-transformer";

import BlockBlock from "../block/block";

export default class StructureInfinity {
  @IsOptional()
  @IsUUID("4")
  public readonly id!: string | null;

  @IsOptional()
  @IsUUID("4")
  public skill_template_id?: string | null;

  @IsString()
  public full_name: string = "";

  @IsString()
  public level_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public parent_id?: string | null;

  @IsOptional()
  @IsUUID("4", { each: true })
  public parent_ids?: string[];

  @IsOptional()
  @IsUUID("4", { each: true })
  public reverse_parent_ids?: string[];

  @IsUUID("4")
  public factory_id?: string;

  @IsOptional()
  @IsUUID("4")
  public inventory_id?: string | null;

  @Type(() => StructureInfinity)
  public childrens?: StructureInfinity[];

  public is_show?: boolean;

  public blocks?: BlockBlock[] = [];

  constructor(data?: any) {
    if (data) {
      this.id = data.id || this.id;
      this.parent_id = data.parent_id || this.parent_id;
      this.factory_id = data.factory_id || this.factory_id;
      this.full_name = data.full_name || this.full_name;
    }
  }
}

export default {
  skills: `/v1/skills/skills`,
  mentor_report: `/v1/skills/mentor_report`,
  potential_mentors: `/v1/skills/potential_mentors`,
  mentors: `/v1/skills/mentors`,
  skill: (id: string) => `/v1/skills/skills/${id}`,
  block_skills: `/v1/skills/skills/block_skills`,
  change_responsible: (id: string) =>
    `/v1/skills/skills/${id}/change_responsible`,
  categories: `/v1/skills/categories`,
  category: (id: number) => `/v1/skills/categories/${id}`,
  appointments: `/v1/skills/appointments`,
  signs: (id: string) => `/v1/skills/${id}/signs`
};


import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import skill_template_routes from "@/api/routes/skill_templates";

import Search from "@/components/Search.vue";
import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import { SkillTemplate } from "@/models/skill/template";

import CSkillTemplateChange, {
  IChangedSkillTemplate
} from "./components/TemplateChange.vue";
import CSkillTemplateDelete, {
  IDeletedSkillTemplate
} from "./components/TemplateDelete.vue";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly templates: SkillTemplate[];
}

@Component({
  name: "VSkillTemplates",
  components: {
    Search,
    Preloader,
    CInfiniteScroll,
    CSkillTemplateChange,
    CSkillTemplateDelete
  }
})
export default class VSkillTemplates extends Vue {
  public preload: boolean = false;
  public preload_infinite: boolean = false;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private search: string = this.$store.state.search;

  public skill_templates: IData["templates"] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        async search => {
          this.search = search;

          await this.initHandler();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedSkillTemplate,
        (changedSkillTemplate: IChangedSkillTemplate) => {
          if (changedSkillTemplate.template_index != -1) {
            if (changedSkillTemplate.template_index != null) {
              this.$set(
                this.skill_templates,
                changedSkillTemplate.template_index,
                changedSkillTemplate.template
              );
            } else {
              this.skill_templates.unshift(changedSkillTemplate.template);
            }
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.deletedSkillTemplate,
        (deletedSkillTemplate: IDeletedSkillTemplate) => {
          if (deletedSkillTemplate.template_index != -1) {
            if (deletedSkillTemplate.template_index != null) {
              this.$delete(
                this.skill_templates,
                deletedSkillTemplate.template_index
              );
            }
          }
        }
      )
    );

    await this.initHandler();
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadSkillTemplates(this.current_page + 1);
    }
  }

  private async initHandler() {
    this.preload = true;
    this.skill_templates = [];

    await this.loadSkillTemplates().finally(() => (this.preload = false));
  }

  private async loadSkillTemplates(page: number = 0) {
    this.preload_infinite = true;

    return this.$api
      .get(skill_template_routes.templates, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.skill_templates.push(
          ...plainToClass(SkillTemplate, res.templates)
        );
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  public createTemplate() {
    this.$store.commit("changingSkillTemplate", {
      id: null,
      template_index: null
    });
  }

  public updateTemplate(id: string | null, template_index: number) {
    this.$store.commit("changingSkillTemplate", {
      id,
      template_index
    });
  }

  public deleteTemplate(template: SkillTemplate, template_index: number) {
    this.$store.commit("deletingSkillTemplate", {
      id: template.id,
      value: template.value,
      template_index
    });
  }
}

export function sortFn<T, K extends keyof T>(
  key: K,
  order: "asc" | "desc" = "asc"
) {
  return function(a: T, b: T) {
    if (a[key] === b[key]) {
      return 0;
    }

    if (
      (order === "asc" && a[key] > b[key]) ||
      (order === "desc" && a[key] < b[key])
    ) {
      return 1;
    }

    return -1;
  };
}

export function searchFn<T, K extends keyof T>(key: K, search?: string) {
  return function(a: T) {
    if (search && typeof a[key] === "string") {
      if (
        ((a[key] as unknown) as string)
          .toLowerCase()
          .includes(search.trim().toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  };
}


import { Component, Vue } from "vue-property-decorator";

import { IRouteMeta } from "@/router/interface";
import structure_routes from "@/api/routes/structures";
import inventory_routes from "@/api/routes/inventories";
import skill_routes from "@/api/routes/skills";
import skill_templates_routes from "@/api/routes/skill_templates";

import Preloader from "@/components/Preloader.vue";
import CFileDownload from "@/components/FileDownload.vue";
import CImportModal from "@/components/ImportModal.vue";

import StructureFactory from "@/models/structure/factory";
import SkillCategory from "@/models/skill/category";
import InventoryInventory from "@/models/inventory/inventory";
import { EImportType } from "@/enums/import_type";
import { SkillTemplate } from "@/models/skill/template";
import BlockBlock from "@/models/block/block";

import CStructureFactoryChange, {
  IChangedStructureFactory,
  IDeletedStructureFactory
} from "./components/FactoryChange.vue";
import CStructureInfinity from "./components/Infinity.vue";
import CStructureInfinityChange from "./components/InfinityChange.vue";

@Component({
  name: "VStructures",
  components: {
    Preloader,
    CFileDownload,
    CImportModal,
    CStructureInfinity,
    CStructureFactoryChange,
    CStructureInfinityChange
  }
})
export default class VStructures extends Vue {
  public preload: boolean = false;

  public EImportType = EImportType;

  public factories: StructureFactory[] = [];
  public current_factory: StructureFactory | null = null;

  public inventories: InventoryInventory[] = [];
  public skill_categories: SkillCategory[] = [];
  public skill_templates: SkillTemplate[] = [];
  public skill_blocks: BlockBlock[] = [];

  $refs!: {
    tabs: HTMLFormElement;
    importModalRef: CImportModal;
  };

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.deletedStructureFactory,
        (deleted_structure_factory: IDeletedStructureFactory) => {
          if (
            deleted_structure_factory.factory_index != null &&
            deleted_structure_factory.factory_index != -1
          ) {
            this.$delete(
              this.factories,
              deleted_structure_factory.factory_index
            );
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedStructureFactory,
        (changed_structure_factory: IChangedStructureFactory) => {
          if (changed_structure_factory.factory_index != -1) {
            if (changed_structure_factory.factory_index != null) {
              const factory = this.factories[
                changed_structure_factory.factory_index
              ];

              this.$set(
                factory,
                "full_name",
                changed_structure_factory.factory.full_name
              );
            } else {
              this.factories.push(changed_structure_factory.factory);
            }
          }
        }
      )
    );

    const promises = [
      this.loadInventories(),
      this.loadCategories(),
      this.loadSkillTemplates(),
      this.loadSkillBlocks()
    ];

    if (this.$api.allowOnlyUserWorkplaces()) {
      promises.push(this.loadUserFactory());
    } else {
      promises.push(this.loadFactories());
    }

    await Promise.all(promises).then(() => (this.preload = false));
  }

  public tabScrollRight() {
    this.$refs.tabs.scrollLeft += 100;
  }

  public tabScrollLeft() {
    this.$refs.tabs.scrollLeft -= 100;
  }

  public showImportModal() {
    this.$refs.importModalRef.onShowModal();
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }

  public setCurrentFactory(factory_index: number) {
    this.current_factory = this.factories[factory_index];
  }

  public createFactory() {
    this.$store.commit("changingStructureFactory", {
      id: null,
      factory_index: null
    });
  }

  public updateFactory(id: string | null, factory_index: number) {
    this.$store.commit("changingStructureFactory", {
      id,
      factory_index
    });
  }

  private async loadFactories() {
    return this.$api
      .get(structure_routes.factories, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;

        if (this.factories.length) {
          this.current_factory = this.factories[0];
        }
      });
  }

  private async loadUserFactory() {
    return this.$api
      .get(structure_routes.me_factory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factories = [res];

        if (this.factories.length) {
          this.current_factory = this.factories[0];
        }
      });
  }

  private async loadInventories() {
    return this.$api
      .get(inventory_routes.inventories, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: InventoryInventory[] }) => {
        this.inventories = res;
      });
  }

  private async loadCategories() {
    return this.$api
      .get(skill_routes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skill_categories = res;
      });
  }

  private async loadSkillTemplates() {
    return this.$api
      .get(skill_templates_routes.templates, {
        params: {
          paginate: false,
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: { templates: SkillTemplate[] } }) => {
        this.skill_templates = res.templates;
      });
  }

  private async loadSkillBlocks() {
    return this.$api
      .get(skill_routes.block_skills, {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: BlockBlock[] }) => {
        this.skill_blocks = res;
      });
  }
}

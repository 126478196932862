
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";
import { SkillTemplate } from "@/models/skill/template";

import skill_template_routes from "@/api/routes/skill_templates";
import { SkillTemplateSkill } from "@/models/skill/template_skill";
import { searchFn, sortFn } from "@/helpers/array";

export interface IChangingSkillTemplate {
  id: string | null;
  template_index: number | null;
}

export interface IChangedSkillTemplate {
  template: SkillTemplate;
  template_index: number | null;
}

@Component({
  name: "CSkillTemplateChange"
})
export default class CSkillTemplateChange extends Vue {
  private modal_opened: boolean = false;

  public template_id: string | null = null;
  private template_index: number | null = null;

  public skill_template: SkillTemplate | null = null;

  public selected_skill_ids: Set<string> = new Set();

  public available_skills: SkillTemplateSkill[] = [];
  public displayed_skills: SkillTemplateSkill[] = [];

  public search: string = "";

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    await Promise.all([this.loadAvailableSkills()]);

    this.watchers.push(
      this.$store.watch(
        state => state.changingSkillTemplate,
        async (changingSkillTemplate: IChangingSkillTemplate | null) => {
          await this.initHandler(changingSkillTemplate);
        }
      )
    );

    if (this.$store.state.changingSkillTemplate && !this.modal_opened) {
      await this.initHandler(this.$store.state.changingSkillTemplate);
    }
  }

  private async initHandler(changing_template: IChangingSkillTemplate | null) {
    this.search = "";
    this.selected_skill_ids.clear();

    if (changing_template != null) {
      this.template_id = changing_template.id;
      this.template_index = changing_template.template_index;

      await Promise.all([this.loadTemplate(), this.loadExistsSkills()]);

      this.onSearch();

      this.showModal();
    } else {
      this.template_id = null;
      this.template_index = null;
      this.skill_template = null;
    }
  }

  public showModal() {
    this.$modal.show("change_skill_template_modal");
    this.modal_opened = true;
  }

  public beforeHideModal(event: { cancel: () => void }) {
    if (this.modal_opened) {
      event.cancel();

      this.$modal.show("confirm_close_change_skill_template_modal");
    }
  }

  public hideModal() {
    this.$modal.hide("change_skill_template_modal");
  }

  public afterHideModal() {
    this.modal_opened = false;

    this.$store.commit("changingSkillTemplate", null);

    this.$modal.hide("confirm_close_change_skill_template_modal");
    this.$modal.hide("change_skill_template_modal");
  }

  private async loadTemplate() {
    if (!this.template_id) {
      this.skill_template = new SkillTemplate();

      return;
    }

    return this.$api
      .get(skill_template_routes.template(this.template_id))
      .then(({ data: res }: { data: SkillTemplate }) => {
        this.skill_template = plainToClass(SkillTemplate, res);
      });
  }

  private async loadAvailableSkills() {
    return this.$api
      .get(skill_template_routes.available_skills)
      .then(({ data: res }: { data: SkillTemplateSkill[] }) => {
        this.available_skills = plainToClass(SkillTemplateSkill, res);
      });
  }

  private async loadExistsSkills() {
    if (!this.template_id) {
      return;
    }

    return this.$api
      .get(skill_template_routes.exists_skills(this.template_id))
      .then(({ data: res }: { data: SkillTemplateSkill[] }) => {
        res.forEach(skill => {
          this.selected_skill_ids.add(skill.id);
        });
      });
  }

  public toggleSelectedSkill(skill: SkillTemplateSkill) {
    if (this.selected_skill_ids.has(skill.id)) {
      this.selected_skill_ids.delete(skill.id);
    } else {
      this.selected_skill_ids.add(skill.id);
    }
  }

  public onSearch() {
    if (this.search.length) {
      this.displayed_skills = Array.from(this.available_skills)
        .filter(searchFn("full_name", this.search))
        .sort(sortFn("full_name"));
    } else {
      this.displayed_skills = Array.from(this.available_skills).sort(
        sortFn("full_name")
      );
    }
  }

  public async createTemplate() {
    return this.$api
      .post(skill_template_routes.templates, {
        skill_template: this.skill_template,
        selected_skill_ids: Array.from(this.selected_skill_ids || [])
      })
      .then(({ data: res }: { data: SkillTemplate }) => {
        this.$store.commit("changedSkillTemplate", {
          template: plainToClass(SkillTemplate, res),
          template_index: this.template_index
        });

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });

        this.afterHideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async updateTemplate() {
    if (!this.template_id) {
      return;
    }

    return this.$api
      .put(skill_template_routes.template(this.template_id), {
        skill_template: this.skill_template,
        selected_skill_ids: Array.from(this.selected_skill_ids || [])
      })
      .then(({ data: res }: { data: SkillTemplate }) => {
        this.$store.commit("changedSkillTemplate", {
          template: plainToClass(SkillTemplate, res),
          template_index: this.template_index
        });

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });

        this.afterHideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

import "reflect-metadata";

import {
  IsUUID,
  IsString,
  IsInt,
  Min,
  Max,
  IsOptional,
  IsBoolean
} from "class-validator";
import { Type } from "class-transformer";

import StructureWorkshop from "./workshop";
import StructureInfinity from "./infinity";
import BlockBlock from "../block/block";
import SkillLocal from "@/models/skill/local";

export default class StructureFactory {
  @IsOptional()
  @IsUUID("4")
  public readonly id!: string | null;

  @IsOptional()
  @IsUUID("4")
  public skill_template_id?: string | null;

  @IsBoolean()
  public uec?: boolean;

  @IsString()
  public full_name: string = "";

  @IsInt()
  @Min(0)
  @Max(5)
  public phase?: number = 0;

  @IsOptional()
  @IsString()
  public level_name?: string = "";

  @IsOptional()
  @IsUUID("4")
  public inventory_id?: string | null;

  @Type(() => StructureWorkshop)
  public workshops?: StructureWorkshop[];

  @Type(() => StructureInfinity)
  public infinities?: StructureInfinity[];

  public is_show?: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id || this.id;
    }
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import { IRouteMeta } from "@/router/interface";
import structure_routes from "@/api/routes/structures";

import { phasesFactory } from "@/consts";
import StructureFactory from "@/models/structure/factory";
import InventoryInventory from "@/models/inventory/inventory";
import SkillCategory from "@/models/skill/category";
import { SkillTemplate } from "@/models/skill/template";
import BlockBlock from "@/models/block/block";

import CStructureSkillChange from "./StructureSkillChange.vue";

export interface IChangingStructureFactory {
  id: string | null;
  factory_index: number | null;
}

export interface IChangedStructureFactory {
  factory: StructureFactory;
  factory_index: number | null;
}

export interface IDeletedStructureFactory {
  factory_index: number;
}

@Component({
  name: "CStructureFactoryChange",
  components: {
    CStructureSkillChange
  }
})
export default class CStructureFactoryChange extends Vue {
  @Prop({ required: true }) public readonly inventories!: InventoryInventory[];
  @Prop({ required: true }) public readonly skill_categories!: SkillCategory[];
  @Prop({ required: true }) public readonly skill_templates!: SkillTemplate[];
  @Prop({ required: true }) public readonly skill_blocks!: BlockBlock[];

  public phases = phasesFactory;

  private modal_opened: boolean = false;

  public factory_id: string | null = null;
  private factory_index: number | null = null;
  public factory: StructureFactory | null = null;
  public factory_blocks: BlockBlock[] | null = null;

  public edit_errors: string | null = null;

  public current_user: IJWTUser | null = this.$store.state.currentUser;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.changingStructureFactory,
        async (changingStructureFactory: IChangingStructureFactory | null) => {
          await this.initHandler(changingStructureFactory);
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    if (this.$store.state.changingStructureFactory && !this.modal_opened) {
      await this.initHandler(this.$store.state.changingStructureFactory);
    }
  }

  private async initHandler(
    changing_structure_factory: IChangingStructureFactory | null
  ) {
    this.edit_errors = null;
    this.factory_blocks = null;

    if (changing_structure_factory != null) {
      this.factory_id = changing_structure_factory.id;
      this.factory_index = changing_structure_factory.factory_index;

      await Promise.all([this.loadFactory(), this.loadFactorySkill()]);

      this.showModal();
    } else {
      this.factory_id = null;
      this.factory_index = null;
      this.factory = null;
    }
  }

  private async loadFactory() {
    if (!this.factory_id) {
      this.factory = new StructureFactory();
      this.factory.uec = false;

      return;
    }

    return this.$api
      .get(structure_routes.factory(this.factory_id), {
        params: {
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factory = res;
      });
  }

  private async loadFactorySkill() {
    return this.$api
      .get(
        structure_routes.factory_block_skills(
          this.factory_id ? this.factory_id : "new"
        ),
        {
          params: {
            section_id: (this.$router.currentRoute.meta! as IRouteMeta)
              .permissions?.[0]
          }
        }
      )
      .then(({ data: res }: { data: BlockBlock[] }) => {
        this.factory_blocks = res.reduce<BlockBlock[]>((acc, curr) => {
          curr.display = true;

          curr.skills?.forEach(skill => {
            if (skill.level && skill.level > 0) {
              skill.display = true;
            }
          });

          acc.push(curr);

          return acc;
        }, []);
      });
  }

  public showModal() {
    this.$modal.show("change_structure_factory_modal");
    this.modal_opened = true;
  }

  public beforeHideModal(event: { cancel: () => void }) {
    if (this.modal_opened) {
      event.cancel();

      this.$modal.show("confirm_close_change_structure_factory_modal");
    }
  }

  public hideModal() {
    this.$modal.hide("change_structure_factory_modal");
  }

  public afterHideModal() {
    this.modal_opened = false;

    this.$store.commit("changingStructureFactory", null);

    this.$modal.hide("confirm_archive_structure_factory_modal");
    this.$modal.hide("confirm_close_change_structure_factory_modal");
    this.$modal.hide("change_structure_factory_modal");
  }

  public beforeArchiveFactory() {
    if (!this.factory_id) {
      return;
    }

    this.$modal.show("confirm_archive_structure_factory_modal");
  }

  public async archiveFactory() {
    if (!this.factory_id) {
      return;
    }

    return this.$api
      .post(structure_routes.factory_archived(this.factory_id), {})
      .then(() => {
        this.$store.commit("deletedStructureFactory", {
          factory_index: this.factory_index
        });

        this.afterHideModal();
      });
  }

  public async updateFactory() {
    if (!this.factory_id) {
      return;
    }

    return this.$api
      .put(structure_routes.factory(this.factory_id), {
        factory: this.factory,
        blocks: this.factory_blocks
      })
      .then(({ data: res }: { data: StructureFactory }) => {
        this.$store.commit("changedStructureFactory", {
          factory: res,
          factory_index: this.factory_index
        });

        this.afterHideModal();
      })
      .catch(({ response: res }) => {
        this.edit_errors = res.data.error;
      });
  }

  public async createFactory() {
    return this.$api
      .post(structure_routes.factories, {
        factory: this.factory,
        blocks: this.factory_blocks
      })
      .then(({ data: res }: { data: StructureFactory }) => {
        this.$store.commit("changedStructureFactory", {
          factory: res,
          factory_index: this.factory_index
        });

        this.afterHideModal();
      })
      .catch(({ response: res }) => {
        this.edit_errors = res.data.error;
      });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}

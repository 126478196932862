
import { Ref, ref } from "vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { classToClass } from "class-transformer";

import { IRouteMeta } from "@/router/interface";
import inventory_routes from "@/api/routes/inventories";
import skill_template_routes from "@/api/routes/skill_templates";

import CSkillRadio from "@/components/SkillRadio.vue";

import BlockBlock, { IBlockSkill } from "@/models/block/block";
import SkillCategory, { ESkillCategory } from "@/models/skill/category";

import { phases } from "@/consts";

@Component({
  name: "CStructureSkillChange",
  components: {
    CSkillRadio
  }
})
export default class CStructureSkillChange extends Vue {
  @Prop({ required: true }) public readonly inventory_id!: string | null; // выбранная группа оборудование
  @Prop({ required: true }) public readonly skill_template_id!: string | null; // выбранный шаблон
  @Prop({ required: true }) public readonly skill_categories!: SkillCategory[]; // категории навыков, для фильтра
  @Prop({ required: true }) public readonly skill_blocks!: BlockBlock[]; // функциональные навыки, для добавления вне шаблона через VDropdown
  @Prop({ required: true }) public readonly blocks!: BlockBlock[]; // навыки уровня, которые будут меняться

  @Prop({ required: true }) public readonly factory_id!: string;
  @Prop({ required: true }) public readonly infinity_id!: string | null;

  public phases = phases;
  public global_level: number = 0;

  public display_zero_levels: boolean = false;
  public select_all_skills: boolean = false;
  public selected_skills_count: number = 0;
  public total_skills_count: number = 0;

  public show_skills_dropdown: boolean = false;

  public inventory_skills: Record<string, Record<string, IBlockSkill>> = {};
  public template_skills: Record<string, Record<string, IBlockSkill>> = {};

  public skill_category_id: number | null = null;
  public skill_categories_options: SkillCategory[] = [
    { id: null, value: "Все" },
    ...this.skill_categories
  ];

  public block_id: string | null = null;
  public blocks_options: BlockBlock[] = [
    { id: null, value: "Все" },
    ...this.blocks.map(block => ({ id: block.id, value: block.full_name }))
  ];

  // Для ререндера функциональных навыков, для добавления вне шаблона через VDropdown
  public selected_functional_skill_key: number = 0;
  public selected_functional_skill_ids: Ref<Set<string>> = ref(
    new Set<string>()
  );
  public skill_blocks_copy = classToClass(this.skill_blocks);

  public search: string = "";
  public dropdown_search: string = "";

  public blocks_opened: boolean = true;

  $refs!: {
    blocks: HTMLDivElement[];
  };

  public current_user: IJWTUser | null = this.$store.state.currentUser;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.selected_functional_skill_ids.value = new Set();

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    this.blocks.forEach(block => {
      this.total_skills_count += block.skills!.length;

      block.skills!.forEach(skill => {
        if (
          skill.category_id === ESkillCategory.FUNCTIONAL_ID &&
          skill.block_skill_origin_id
        ) {
          this.selected_functional_skill_ids.value.add(
            skill.block_skill_origin_id
          );
        }
      });
    });

    this.skill_blocks_copy.forEach(block => {
      block.display =
        block.skills!.filter(
          skill =>
            !this.selected_functional_skill_ids.value.has(
              skill.block_skill_origin_id!
            )
        ).length > 0;
    });

    this.selected_functional_skill_key++;
  }

  @Watch("inventory_id")
  protected async changeInventoryId() {
    await this.loadInventorySkills();

    this.applyInventory();
  }

  @Watch("skill_template_id")
  protected async changeSkillTemplateId() {
    await this.loadTemplateSkills();

    this.applySkillTemplate();
  }

  public changeDisplayZeroLevels() {
    this.blocks.forEach(block => {
      block.skills?.forEach(skill => {
        skill.display = true;

        if (
          skill.level != null &&
          skill.level === 0 &&
          !this.display_zero_levels
        ) {
          skill.display = false;
        }

        if (
          this.search.length &&
          !skill.full_name
            ?.toLowerCase()
            ?.includes(this.search.trim().toLowerCase())
        ) {
          skill.display = false;
        }

        if (
          this.skill_category_id &&
          this.skill_category_id !== skill.category_id
        ) {
          skill.display = false;
        }
      });
    });
  }

  public changeSkillCategory() {
    this.blocks.forEach(block => {
      let skills_count = 0;

      block.skills!.forEach(skill => {
        if (
          !this.skill_category_id ||
          this.skill_category_id === skill.category_id
        ) {
          skill.display = true;

          if (
            this.search.length &&
            !skill.full_name
              ?.toLowerCase()
              ?.includes(this.search.trim().toLowerCase())
          ) {
            skill.display = false;
          }

          if (skill.display) {
            skills_count++;
          }

          if (
            skill.level != null &&
            skill.level === 0 &&
            !this.display_zero_levels
          ) {
            skill.display = false;
          }
        } else {
          skill.display = false;
        }
      });

      block.skill_count = skills_count;
    });
  }

  public changeBlock() {
    this.blocks.forEach(block => {
      block.display = !this.block_id || this.block_id === block.id!;
    });
  }

  public onSearch() {
    this.blocks.forEach(block => {
      let skills_count = 0;

      block.skills!.forEach(skill => {
        skill.display = true;

        if (skill.selected) {
          skill.display = false;
        }

        if (
          this.search.length &&
          !skill.full_name?.toLowerCase()?.includes(this.search.toLowerCase())
        ) {
          skill.display = false;
        }

        if (
          this.skill_category_id &&
          this.skill_category_id !== skill.category_id
        ) {
          skill.display = false;
        }

        if (skill.display) {
          skills_count++;
        }

        if (
          skill.level != null &&
          skill.level === 0 &&
          !this.display_zero_levels
        ) {
          skill.display = false;
        }
      });

      block.skill_count = skills_count;
    });
  }

  public onSearchDropdown() {
    this.skill_blocks_copy.forEach(block => {
      let count = 0;

      block.skills!.forEach(skill => {
        skill.display = true;

        if (
          this.selected_functional_skill_ids.value.has(
            skill.block_skill_origin_id!
          )
        ) {
          skill.display = false;
        }

        if (
          this.dropdown_search.length &&
          !skill.full_name
            ?.toLowerCase()
            ?.includes(this.dropdown_search.toLowerCase())
        ) {
          skill.display = false;
        }

        if (skill.display) {
          count++;
        }
      });

      block.display = count > 0;
    });
  }

  public showAdditionalSkillDeleteBtn(skill: IBlockSkill) {
    return (
      (!skill.parent_id || !skill.block_skill_local_parent_id) &&
      !skill.skill_template_id &&
      skill.category_id === ESkillCategory.FUNCTIONAL_ID &&
      this.current_user?.is_admin
    );
  }

  public delAdditionalSkill(block_index: number, skill_index: number) {
    const block = this.blocks[block_index];
    const skill = block.skills![skill_index];

    if (skill.selected) {
      this.selected_skills_count--;
    }

    this.total_skills_count--;

    this.$delete(block.skills!, skill_index);
    this.$set(block, "skill_count", block.skills!.length);

    this.selected_functional_skill_ids.value.delete(
      skill.block_skill_origin_id!
    );

    const target_block = this.skill_blocks_copy.find(b => b.id === block.id);

    if (target_block) {
      target_block.display = true;
    }

    this.selected_functional_skill_key++;
  }

  public addAdditionalSkill(block: BlockBlock, skill: IBlockSkill) {
    this.selected_functional_skill_ids.value.add(skill.block_skill_origin_id!);

    block.display =
      block.skills!.filter(
        skill =>
          !this.selected_functional_skill_ids.value.has(
            skill.block_skill_origin_id!
          )
      ).length > 0;

    let target_block = this.blocks.find(b => b.id === block.id);

    if (!target_block) {
      target_block = new BlockBlock();
      target_block.id = block.id;
      target_block.full_name = block.full_name;
      target_block.skill_count = 0;
      target_block.display = true;
      target_block.skills = [];

      this.blocks.push(target_block);
    }

    const target_skill = target_block.skills!.find(
      s => s.block_skill_origin_id! === skill.block_skill_origin_id!
    );

    if (!target_skill) {
      const new_skill = { ...skill };

      if (
        this.inventory_skills[block.id!]?.[new_skill.block_skill_origin_id!]
      ) {
        const inventory_level =
          this.inventory_skills[block.id!][new_skill.block_skill_origin_id!]
            .level ?? 0;

        if (!new_skill.level || new_skill.level < inventory_level) {
          new_skill.level = inventory_level;
        }
      }

      new_skill.display = true;

      if (
        this.skill_category_id &&
        new_skill.category_id !== this.skill_category_id
      ) {
        new_skill.display = false;
      }

      if (
        this.search.length &&
        !new_skill.full_name?.toLowerCase()?.includes(this.search.toLowerCase())
      ) {
        new_skill.display = false;
      }

      if (
        new_skill.level != null &&
        new_skill.level === 0 &&
        !this.display_zero_levels
      ) {
        new_skill.display = false;
      }

      if (this.select_all_skills) {
        new_skill.selected = true;

        this.selected_skills_count++;
      }

      this.total_skills_count++;

      target_block.skills!.push(new_skill);
      target_block.skill_count = target_block.skills!.length;
    }

    this.selected_functional_skill_key++;
  }

  private async loadInventorySkills() {
    if (!this.inventory_id) {
      this.inventory_skills = {};

      return;
    }

    return this.$api
      .get(inventory_routes.block_skills(this.inventory_id), {
        params: {
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: IBlockSkill[] }) => {
        this.inventory_skills = res.reduce<
          Record<string, Record<string, IBlockSkill>>
        >((acc, curr) => {
          if (!acc[curr.block_id!]) {
            acc[curr.block_id!] = {};
          }

          if (!acc[curr.block_id!][curr.block_skill_origin_id!]) {
            acc[curr.block_id!][curr.block_skill_origin_id!] = curr;
          }

          return acc;
        }, {});
      });
  }

  private async loadTemplateSkills() {
    if (!this.skill_template_id) {
      this.template_skills = {};

      return;
    }

    return this.$api
      .get(skill_template_routes.block_skills(this.skill_template_id), {
        params: {
          factory_id: this.factory_id,
          infinity_id: this.infinity_id,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(
        ({
          data: res
        }: {
          data: { skills: IBlockSkill[]; blocks: BlockBlock[] };
        }) => {
          res.blocks.forEach(block => {
            let target_block = this.blocks.find(b => b.id === block.id);

            if (!target_block) {
              target_block = new BlockBlock();
              target_block.id = block.id;
              target_block.full_name = block.full_name;
              target_block.skill_count = 0;
              target_block.display = true;
              target_block.skills = [];

              this.blocks.push(target_block);
            }
          });

          this.template_skills = res.skills.reduce<
            Record<string, Record<string, IBlockSkill>>
          >((acc, curr) => {
            if (!acc[curr.block_id!]) {
              acc[curr.block_id!] = {};
            }

            if (!acc[curr.block_id!][curr.block_skill_origin_id!]) {
              acc[curr.block_id!][curr.block_skill_origin_id!] = curr;
            }

            return acc;
          }, {});
        }
      );
  }

  private applyInventory() {
    this.blocks.forEach(block => {
      if (this.inventory_skills[block.id!]) {
        block.skills!.forEach(skill => {
          if (
            !skill.is_manual &&
            skill.block_skill_origin_id &&
            this.inventory_skills[block.id!]?.[skill.block_skill_origin_id]
          ) {
            const inventory_level =
              this.inventory_skills[block.id!][skill.block_skill_origin_id!]
                .level ?? 0;

            skill.level = inventory_level;

            this.$set(
              skill,
              "display",
              inventory_level ? true : this.display_zero_levels
            );
          }
        });
      }
    });
  }

  private applySkillTemplate() {
    this.blocks.forEach(block => {
      const skip_ids: Set<string> = new Set();

      block.skills = Array.from(block.skills!).reduce<IBlockSkill[]>(
        (acc, curr) => {
          if (!curr.skill_template_id) {
            acc.push(curr);

            if (
              curr.block_skill_origin_id &&
              curr.category_id === ESkillCategory.FUNCTIONAL_ID
            ) {
              skip_ids.add(curr.block_skill_origin_id);
            }
          } else if (
            curr.skill_template_id &&
            curr.block_skill_origin_id &&
            this.template_skills[block.id!]?.[curr.block_skill_origin_id]
          ) {
            skip_ids.add(curr.block_skill_origin_id);

            acc.push(curr);
          } else {
            this.total_skills_count--;

            if (
              curr.block_skill_origin_id &&
              curr.category_id === ESkillCategory.FUNCTIONAL_ID
            ) {
              this.selected_functional_skill_ids.value.delete(
                curr.block_skill_origin_id
              );
            }

            if (curr.selected) {
              this.selected_skills_count--;
            }
          }

          return acc;
        },
        []
      );

      if (this.template_skills[block.id!]) {
        Object.values(this.template_skills[block.id!]).forEach(skill => {
          if (!skip_ids.has(skill.block_skill_origin_id!)) {
            if (
              this.inventory_skills[block.id!]?.[skill.block_skill_origin_id!]
            ) {
              const inventory_level =
                this.inventory_skills[block.id!][skill.block_skill_origin_id!]
                  .level ?? 0;

              if (
                !skill.is_manual &&
                (!skill.level || skill.level < inventory_level)
              ) {
                skill.level = inventory_level;
              }
            }

            skill.display = true;

            if (
              this.skill_category_id &&
              skill.category_id !== this.skill_category_id
            ) {
              skill.display = false;
            }

            if (
              this.search.length &&
              !skill.full_name
                ?.toLowerCase()
                ?.includes(this.search.toLowerCase())
            ) {
              skill.display = false;
            }

            if (
              skill.level != null &&
              skill.level === 0 &&
              !this.display_zero_levels
            ) {
              skill.display = false;
            }

            if (this.select_all_skills) {
              skill.selected = true;

              this.selected_skills_count++;
            }

            this.total_skills_count++;

            this.selected_functional_skill_ids.value.add(
              skill.block_skill_origin_id!
            );

            block.skills!.push(skill);
          }
        });
      }

      this.selected_functional_skill_key++;

      block.skill_count = block.skills!.length;
    });
  }

  public toggleBlocks() {
    this.blocks_opened = !this.blocks_opened;

    if (this.blocks_opened) {
      this.$refs.blocks.map(l => l.classList.remove("close"));
    } else {
      this.$refs.blocks.map(l => l.classList.add("close"));
    }
  }

  public toggleBlock(event: MouseEvent) {
    if (event.target && event.target instanceof HTMLDivElement) {
      event.target.classList.toggle("close");
    }
  }

  public changeSkillLevel(
    data: IBlockSkill,
    block_index: number,
    skill_index: number
  ) {
    if (this.blocks[block_index].skills?.[skill_index]) {
      data.is_manual = true;
      data.display = true;

      this.$set(this.blocks[block_index].skills!, skill_index, { ...data });
    }
  }

  public selectAllSkills() {
    this.selected_skills_count = 0;

    if (this.select_all_skills) {
      this.blocks.forEach(block => {
        block.skills?.forEach(skill => {
          skill.selected = false;
        });
      });

      this.select_all_skills = false;
    } else {
      this.blocks.forEach(block => {
        block.skills?.forEach(skill => {
          skill.selected = true;
        });

        this.selected_skills_count += block.skills?.length ?? 0;
      });

      this.select_all_skills = true;
    }
  }

  public selectedSkillsCountIsPositive() {
    return this.selected_skills_count > 0;
  }

  public selectAllIcon() {
    if (this.selectedSkillsCountIsPositive()) {
      if (this.select_all_skills) {
        return "/img/icon-plus.svg";
      } else {
        return "/img/icon-minus.svg";
      }
    }

    return null;
  }

  public selectSkill(skill: IBlockSkill) {
    this.$set(skill, "selected", !skill.selected);

    this.selected_skills_count += skill.selected ? 1 : -1;
    this.select_all_skills =
      this.selected_skills_count === this.total_skills_count;
  }

  public changeSelectedSkillsLevel() {
    this.blocks.forEach(block => {
      block.skills?.forEach(skill => {
        if (skill.selected) {
          skill.level = this.global_level;
          skill.is_manual = true;

          this.$set(
            skill,
            "display",
            skill.level ? true : this.display_zero_levels
          );
        }
      });
    });
  }

  public toggleShowSkillsDropdown() {
    this.show_skills_dropdown = !this.show_skills_dropdown;
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}

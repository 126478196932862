export class SkillTemplate {
  public readonly id!: string | null;
  public readonly created_at!: string | null;
  public readonly updated_at!: string | null;

  public readonly skills_count!: number;

  public value: string = "";
  public is_archived: boolean = false;

  public get short() {
    const words = this.value.split(" ");

    const first_char = words[0]?.[0];
    const last_char = words[words.length - 1]?.[0];

    let short = "";

    if (first_char) {
      short += first_char.toUpperCase();
    }

    if (words.length > 1 && last_char) {
      short += last_char.toUpperCase();
    }

    return short;
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import structure_routes from "@/api/routes/structures";

import Preloader from "@/components/Preloader.vue";

import StructureFactory from "@/models/structure/factory";
import StructureInfinity from "@/models/structure/infinity";

import {
  IChangedStructureInfinity,
  IDeletedStructureInfinity
} from "./InfinityChange.vue";

@Component({
  name: "CStructureInfinity",
  components: {
    Preloader
  }
})
export default class CStructureInfinity extends Vue {
  @Prop({ required: true }) private factory!: StructureFactory;
  @Prop({ required: true }) private parent!: StructureInfinity | null;

  public preload: boolean = false;

  public infinities: StructureInfinity[] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.deletedStructureInfinity,
        (deleted_structure_infinity: IDeletedStructureInfinity) => {
          if (
            deleted_structure_infinity.infinity_index != null &&
            deleted_structure_infinity.infinity_index != -1 &&
            deleted_structure_infinity.parent_id === (this.parent?.id || null)
          ) {
            this.$delete(
              this.infinities,
              deleted_structure_infinity.infinity_index
            );
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedStructureInfinity,
        (change_structure_infinity: IChangedStructureInfinity) => {
          if (
            change_structure_infinity.infinity_index != -1 &&
            change_structure_infinity.parent_id === (this.parent?.id || null)
          ) {
            if (change_structure_infinity.infinity_index != null) {
              const infinity = this.infinities[
                change_structure_infinity.infinity_index
              ];

              this.$set(
                infinity,
                "full_name",
                change_structure_infinity.infinity.full_name
              );
            } else {
              change_structure_infinity.infinity.is_show = false;

              this.infinities.push(change_structure_infinity.infinity);
            }
          }
        }
      )
    );

    await this.loadInfinities();

    this.preload = false;
  }

  private async loadInfinities() {
    this.preload = true;

    return this.$api
      .get(structure_routes.infinities, {
        params: {
          factory_id: this.factory!.id,
          parent_id: this.parent?.id
        }
      })
      .then(({ data: res }: { data: StructureInfinity[] }) => {
        res.forEach(r => (r.is_show = false));

        this.infinities = res;
      });
  }

  public toggleInfinity(infinity_index: number) {
    const is_show = this.infinities[infinity_index].is_show;

    this.$set(this.infinities[infinity_index], "is_show", !is_show);
  }

  public createInfinity() {
    this.$store.commit("changingStructureInfinity", {
      id: null,
      infinity_index: null,
      parent_id: this.parent?.id || null,
      factory_id: this.factory.id
    });
  }

  public updateInfinity(id: string | null, infinity_index: number) {
    this.$store.commit("changingStructureInfinity", {
      id,
      infinity_index,
      parent_id: this.parent?.id || null,
      factory_id: this.factory.id
    });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}

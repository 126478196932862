
import { Component, Prop, Vue } from "vue-property-decorator";

import { phases } from "@/consts";

import { IBlockSkill } from "@/models/block/block";

@Component
export default class CSkillRadio extends Vue {
  @Prop({ required: false, default: false }) public disabled!: boolean;
  @Prop({ required: false }) public disabled_level?: number | null;

  @Prop({ required: false, default: false })
  public show_another_level!: boolean;

  @Prop({ required: false, default: false })
  public show_delete_btn!: boolean;

  @Prop({ required: true }) public skill!: IBlockSkill;
  @Prop({ required: true }) public idElement!: string;
  @Prop({ required: true }) public prefix!: string;

  public phases = phases;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    if (this.skill!.level! < this.disabled_level!) {
      this.skill.level = this.disabled_level;
    }

    this.watchers.push(
      this.$store.watch(
        state => state.editedSkillReload,
        () => {
          if (
            this.$store.state.editedSkillIdElement === this.idElement &&
            this.prefix === this.$store.state.editedSkillPrefix
          ) {
            this.skill.level = this.$store.state.editedSkillLevel;
          }
        }
      )
    );
  }

  public disabledPhase(phase: number) {
    if (this.disabled_level != null) {
      return phase < this.disabled_level || this.disabled;
    }

    return this.disabled;
  }

  public showAnotherLevel(phase: number) {
    return (
      this.show_another_level &&
      this.skill.another_level &&
      phase === this.skill.another_level
    );
  }

  public changeLevel(level: number) {
    this.$store.commit("editedSkillReload", Date.now());
    this.$store.commit("editedSkillLevel", level);
    this.$store.commit("editedSkillPrefix", this.prefix);
    this.$store.commit("editedSkillId", this.skill.id);
    this.$store.commit("editedSkillIdElement", this.idElement);

    this.skill.is_manual = true;
    this.$emit("input", this.skill);
  }

  public deleteSkill() {
    this.$emit("delete", this.skill);
  }
}

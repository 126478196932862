
import { Component, Vue, Prop } from "vue-property-decorator";

import { IRouteMeta } from "@/router/interface";
import structure_routes from "@/api/routes/structures";

import StructureInfinity from "@/models/structure/infinity";
import InventoryInventory from "@/models/inventory/inventory";
import SkillCategory from "@/models/skill/category";
import { SkillTemplate } from "@/models/skill/template";
import StructureFactory from "@/models/structure/factory";
import BlockBlock from "@/models/block/block";

import CStructureSkillChange from "./StructureSkillChange.vue";

export interface IChangingStructureInfinity {
  id: string | null;
  infinity_index: number | null;
  parent_id: string | null;
  factory_id: string;
}

export interface IChangedStructureInfinity {
  infinity: StructureInfinity;
  infinity_index: number | null;
  parent_id: string | null;
  factory_id: string;
}

export interface IDeletedStructureInfinity {
  infinity_index: number;
  parent_id: string | null;
  factory_id: string;
}

@Component({
  name: "CStructureInfinityChange",
  components: {
    CStructureSkillChange
  }
})
export default class CStructureInfinityChange extends Vue {
  @Prop({ required: true }) public readonly factory!: StructureFactory;
  @Prop({ required: true }) public readonly inventories!: InventoryInventory[];
  @Prop({ required: true }) public readonly skill_categories!: SkillCategory[];
  @Prop({ required: true }) public readonly skill_templates!: SkillTemplate[];
  @Prop({ required: true }) public readonly skill_blocks!: BlockBlock[];

  private modal_opened: boolean = false;

  public infinity_id: string | null = null;
  private infinity_index: number | null = null;
  private infinity_parent_id: string | null = null;
  private infinity_factory_id: string | null = null;

  public infinity: StructureInfinity | null = null;
  public infinity_blocks: BlockBlock[] | null = null;

  public edit_errors: string | null = null;

  public current_user: IJWTUser | null = this.$store.state.currentUser;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.changingStructureInfinity,
        async (
          changingStructureInfinity: IChangingStructureInfinity | null
        ) => {
          await this.initHandler(changingStructureInfinity);
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    if (this.$store.state.changingStructureInfinity && !this.modal_opened) {
      await this.initHandler(this.$store.state.changingStructureInfinity);
    }
  }

  private async initHandler(
    changing_structure_infinity: IChangingStructureInfinity | null
  ) {
    this.edit_errors = null;
    this.infinity_blocks = null;

    if (changing_structure_infinity != null) {
      this.infinity_id = changing_structure_infinity.id;
      this.infinity_index = changing_structure_infinity.infinity_index;
      this.infinity_parent_id = changing_structure_infinity.parent_id;
      this.infinity_factory_id = changing_structure_infinity.factory_id;

      await Promise.all([this.loadInfinity(), this.loadInfinitySkill()]);

      this.showModal();
    } else {
      this.infinity_id = null;
      this.infinity_index = null;
      this.infinity_parent_id = null;
      this.infinity_factory_id = null;
      this.infinity = null;
    }
  }

  private async loadInfinity() {
    if (!this.infinity_id) {
      this.infinity = new StructureInfinity();
      this.infinity.factory_id = this.infinity_factory_id!;
      this.infinity.parent_id = this.infinity_parent_id;

      return;
    }

    return this.$api
      .get(structure_routes.infinity(this.infinity_id), {
        params: {
          matrix: true,
          only_my: true,
          section_id: (this.$router.currentRoute.meta! as IRouteMeta)
            .permissions?.[0]
        }
      })
      .then(({ data: res }: { data: StructureInfinity }) => {
        this.infinity = res;
      });
  }

  private async loadInfinitySkill() {
    return this.$api
      .get(
        structure_routes.infinity_block_skills(
          this.infinity_id ? this.infinity_id : "new"
        ),
        {
          params: {
            factory_id: this.infinity_factory_id,
            parent_id: this.infinity_parent_id,
            section_id: (this.$router.currentRoute.meta! as IRouteMeta)
              .permissions?.[0]
          }
        }
      )
      .then(({ data: res }: { data: BlockBlock[] }) => {
        this.infinity_blocks = res.reduce<BlockBlock[]>((acc, curr) => {
          curr.display = true;

          curr.skills?.forEach(skill => {
            if (skill.level && skill.level > 0) {
              skill.display = true;
            }
          });

          acc.push(curr);

          return acc;
        }, []);
      });
  }

  public showModal() {
    this.$modal.show("change_structure_infinity_modal");
    this.modal_opened = true;
  }

  public beforeHideModal(event: { cancel: () => void }) {
    if (this.modal_opened) {
      event.cancel();

      this.$modal.show("confirm_close_change_structure_infinity_modal");
    }
  }

  public hideModal() {
    this.$modal.hide("change_structure_infinity_modal");
  }

  public afterHideModal() {
    this.modal_opened = false;

    this.$store.commit("changingStructureInfinity", null);

    this.$modal.hide("confirm_archive_structure_infinity_modal");
    this.$modal.hide("confirm_close_change_structure_infinity_modal");
    this.$modal.hide("change_structure_infinity_modal");
  }

  public beforeArchiveInfinity() {
    if (!this.infinity_id) {
      return;
    }

    this.$modal.show("confirm_archive_structure_infinity_modal");
  }

  public async archiveInfinity() {
    if (!this.infinity_id) {
      return;
    }

    return this.$api
      .post(structure_routes.infinity_archived(this.infinity_id), {})
      .then(() => {
        this.$store.commit("deletedStructureInfinity", {
          infinity_index: this.infinity_index,
          parent_id: this.infinity_parent_id,
          factory_id: this.infinity_factory_id
        });

        this.afterHideModal();
      });
  }

  public async updateInfinity() {
    if (!this.infinity_id) {
      return;
    }

    return this.$api
      .put(structure_routes.infinity(this.infinity_id), {
        infinity: this.infinity,
        blocks: this.infinity_blocks
      })
      .then(({ data: res }: { data: StructureInfinity }) => {
        this.$store.commit("changedStructureInfinity", {
          infinity: res,
          infinity_index: this.infinity_index,
          parent_id: this.infinity_parent_id,
          factory_id: this.infinity_factory_id
        });

        this.afterHideModal();
      })
      .catch(({ response: res }) => {
        this.edit_errors = res.data.error;
      });
  }

  public async createInfinity() {
    return this.$api
      .post(structure_routes.infinities, {
        infinity: this.infinity,
        blocks: this.infinity_blocks
      })
      .then(({ data: res }: { data: StructureInfinity }) => {
        this.$store.commit("changedStructureInfinity", {
          infinity: res,
          infinity_index: this.infinity_index,
          parent_id: this.infinity_parent_id,
          factory_id: this.infinity_factory_id
        });

        this.afterHideModal();
      })
      .catch(({ response: res }) => {
        this.edit_errors = res.data.error;
      });
  }

  public allowWrite() {
    return this.$api.allowWrite();
  }
}
